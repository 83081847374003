import React from "react"
import PropTypes from "prop-types"
import ContactTemplate from "templates/ContactTemplate"

const ContactPage = ({ location }) => (
  <ContactTemplate location={location} lang="ES" />
)

ContactPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

export default ContactPage
